<template>
  <section class="wysiwyg-container container p-4 lg:p-30px" :class="item.container_class">
    <wysiwyg :item="item.content" :tag-filter="styleTag"/>
  </section>
</template>

<script>
import { Blok } from 'src/modules/vsf-storyblok-module/components'
import Wysiwyg from '../Blocks/Wysiwyg'

export default {
  name: 'WysiwygContainer',
  extends: Blok,
  components: {
    Wysiwyg
  },
  methods: {
    styleTag (node, tag, data) {
      return { tag, data }
    }
  }
}
</script>

<style lang="scss">
.wysiwyg-container {
  max-width: 768px;
}
</style>
